.holdALl {
  background-color: #f1f1f1;
  padding-top: 80px;
}

.holdAllTeamText {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.holdAllTeamText1 {
  width: 800px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #313131;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 141.02%;
    color: #313131;
    width: 520px;
  }
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

.holdFrenqently {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.holdFrenqently2 {
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}

.holdMiniBox {
  width: 250px;
  height: 300px;
}

.miniBoxImage {
  width: 250px;
  img {
    height: 300px;
    width: 100%;
  }
}

.miniBoxText {
  width: 250px;
  height: 300px;
  background-color: #34333394;
  display: flex;
  position: absolute;
  border-radius: 10px;
  overflow: hidden;
}

.miniBoxText2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  width: 100%;
}

.LekkiPride1Text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  color: #ffffff;
  margin: 0;
}

.LekkiPride1Text2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.holdSVG {
  font-size: 40px;
  color: white;
}

@media only screen and (max-width: 1200px) {
  .holdAllTeamText {
    padding: 10px;
  }

  .holdAllTeamText1 {
    width: 100%;

    h5 {
      text-align: center;
    }

    h3 {
      font-size: 17px;
      text-align: center;
    }

    p {
      text-align: center;
      width: 100%;
    }
  }

  .holdAllProfileKoko {
    justify-content: center;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .callUs {
    width: 100%;
  }

  .Reach {
    font-size: 20px;
  }

  .question {
    width: 100%;
  }

  .holdFrenqently2 {
    justify-content: center;
  }
}
