.holdAll {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #313131;
    padding: 50px 100px;
}

.Location {
  width: 300px;

  img {
      background-color: white;
      padding: 7px 10px;
      border-radius: 5px;
  
    }
  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 156.18%;
    color: #FFFFFF;
    margin: 10px 0;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 230.68%;
    color: #FFFFFF;
  }
}

.AboutTiwa {
  width: 200px;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 156.18%;
    color: #FFFFFF;
    margin: 10px 0;
  }

  ul {
    list-style: none;
  }

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 303.68%;
    color: #FFFFFF;
    text-decoration: none;
  }
}

.Support {
  width: 200px;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 156.18%;
    color: #FFFFFF;
    margin: 10px 0;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 156.18%;
    color: #FFFFFF;
    margin: 10px 0;
  }

  ul {
    list-style: none;
  }

  a {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 303.68%;
    color: #FFFFFF;
    text-decoration: none;
  }
}

.Support1 {
  width: 150px;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 156.18%;
    color: #FFFFFF;
    margin: 10px 0;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      background-color: gray;
      color: white;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .holdAll {
    padding: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
