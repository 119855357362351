.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 50px;
    background-color: #FFCC29;

    img {
        background-color: white;
        padding: 10px;
        border-radius: 5px;
    }

    a {
        padding: 10px 23px;
        font-size: 17px;
        color: black;
        background-color: white;
        border: none;
        border-radius: 5px;
        font-weight: 500;
    }
}

.success {
    color: white;
    background-color: #4CAF50;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;

    span {
        color: white;
    }

    svg {
        cursor: pointer;
    }
}
.content {
    background-color: #FFF7DD;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    padding-bottom: 50px;

    .holdCountdown {
        width: 45%;

        h1 {
            color: #54595F;
            font-size: 35px;
            line-height: 45px;
        }

        p {
            color: #7A7A7A;
            font-size: 15px;
            margin: 15px 0;
        }

        h2 {
            text-align: center;
            margin-top: 20px;
            font-size: 20px;
            color: #ffcc29;
        }
    }

    .holdForm {
        width: 35%;
        margin-top: 70px;

        .holdFormText {
            text-align: center;
            display: flex;
            justify-content: center;

            h1 {
                width: 100%;
                color: #54595F;
                line-height: 30px;
                font-size: 20px;
            }
        }

        form {
            background-color: white;
            border-radius: 10px;
            padding: 20px;

            div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 20px;

                label {
                    color: #54595f;
                    font-size: 16px;
                }

                input {
                    padding: 8px;
                    font-size: 13px;
                    border: 1.5px solid #bfbfbf;
                    border-radius: 3px;
                    outline: none;
                    color: #bfbfbf;
                }

                textarea {
                    padding: 8px;
                    font-size: 13px;
                    border: 1.5px solid #bfbfbf;
                    border-radius: 3px;
                    outline: none;
                    color: #bfbfbf;
                }
            }

            button {
                width: 100%;
                padding: 10px;
                font-size: 17px;
                font-weight: 600;
                border: none;
                background-color: #ffcc29;
                border-radius: 5px;
            }
        }
    }
}

.holdAttend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 50px;
    background-color: white;
    height: 100vh;

    div {
        width: 45%;

        h1 {
            font-size: 27px;
            color: #ffcc29;
        }

        p {
            color: #7A7A7A;
            font-size: 18px;
            margin: 15px 0;
            line-height: 30px;
        }

        a {
            padding: 10px 20px;
            font-size: 17px;
            border: none;
            background-color: #ffcc29;
            color: white;
            border-radius: 5px;
        }
    }

    div {
        height: 60%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.holdWebinar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 50px;
    background-color: #FFF7DD;
    height: auto;

    .holdWebinar1 {
        width: 45%;

        h2 {
            color: #54595F;
            font-size: 35px;
            line-height: 45px;
        }

        div {
            margin: 20px 0;

            h1 {
                font-size: 20px;
                color: #ffcc29;
            }

            p {
                color: #7A7A7A;
            }
        }
    }

    .holdWebinar2 {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 70%;
        }
    }
}

.holdTrust {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 50px;
    background-color: white;
    height: 100vh;

    div {
        width: 45%;

        h1 {
            font-size: 27px;
            color: #ffcc29;
        }

        p {
            color: #7A7A7A;
            font-size: 18px;
            margin: 15px 0;
            line-height: 30px;
        }

        a {
            padding: 10px 20px;
            font-size: 17px;
            border: none;
            background-color: #ffcc29;
            color: white;
            border-radius: 5px;
        }
    }

    div {
        height: 60%;

        iframe {
            width: 100%;
            height: 100%;
        }
    }
}

.popup {
    width: 100%;
    height: 100vh;
    background-color: #000000b5;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup2 {
    width: 500px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;

    p {
        margin: 0;
        text-align: right;
        margin-bottom: -5px;
        font-size: 20px;
        color: gray;
        padding: 0 10px;

        svg {
            cursor: pointer;
        }
    }

    .holdForm {
        width: 100%;
        margin-top: 0;

        .holdFormText {
            text-align: center;
            display: flex;
            justify-content: center;

            h1 {
                width: 100%;
                color: #54595F;
                line-height: 30px;
                font-size: 20px;
            }
        }

        form {
            background-color: white;
            border-radius: 10px;
            padding: 20px;

            div {
                display: flex;
                flex-direction: column;
                gap: 5px;
                margin-bottom: 20px;

                label {
                    color: #54595f;
                    font-size: 16px;
                }

                input {
                    padding: 8px;
                    font-size: 13px;
                    border: 1.5px solid #bfbfbf;
                    border-radius: 3px;
                    outline: none;
                    color: #bfbfbf;
                }

                textarea {
                    padding: 8px;
                    font-size: 13px;
                    border: 1.5px solid #bfbfbf;
                    border-radius: 3px;
                    outline: none;
                    color: #bfbfbf;
                }
            }

            button {
                width: 100%;
                padding: 10px;
                font-size: 17px;
                font-weight: 600;
                border: none;
                background-color: #ffcc29;
                border-radius: 5px;
            }
        }
    }
}
.holdTakeaways {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 50px;
    background-color: #FFF7DD;
    height: 100vh;

    div {
        width: 45%;

        div {
            width: 100%;

            h1 {
                color: #54595F;
                font-size: 35px;
                line-height: 45px;
            }

            p {
                color: #7A7A7A;
                font-size: 17px;
                margin: 15px 0;
                line-height: 30px;

                svg {
                    font-size: 27px;
                    margin-right: 5px;
                    color: #ffcc29;
                }
            }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                // justify-content: center;
img {
        width: 70%;
    }
    }
}

.holdCountDown {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border: 2px solid #ffcc29;
    border-radius: 5px;

    p {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span:first-child {
            font-size: 25px;
            font-weight: bold;
        }

        span:last-child {
            font-size: 15px;
            font-weight: 500;
        }
    }
}

.AlabiSamuel {
    // padding: 20px 250px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}

.AlabiSamue2 {
    background: #ffffff;
    box-shadow: 0px 4px 4px #00000040;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 350px;
    height: 280px;
    padding: 15px;
}

.testOO {
    margin-top: -80px !important;
}

.Ellipse1 {
    height: 50px;
    margin-right: 20px;
}

.testimonial {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 50px;
    background-color: white;
    flex-direction: column;
    height: 100vh;
    gap: 50px;
}

.testimonial2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.MediaDev {
    h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 19px;
        color: #000000;
        margin: 0;
    }

    .Dev {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #313131;
        margin: 0;
    }

    p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #313131;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 700px) {
    .popup2 {
            width: 90%;
        }
    .header {
        padding: 10px;
    }

    .holdFormText {
        text-align: center;
        display: flex;
        justify-content: center;

        h1 {
            width: 100% !important;
        }
    }

    .content {
        padding: 10px;
        flex-direction: column;

        .holdCountdown {
            width: 100% !important;
            margin-top: 20px;
            text-align: left;
            
                h2 {
                    background-color: tomato;
                    color: white;
                    padding: 18px;
                    border-radius: 5px;
                }
        }

        .holdForm {
            width: 100%;
            padding-bottom: 50px;
            padding-inline: 20px;
        }
    }

    .holdAttend {
        flex-direction: column;
        height: auto;
        padding: 50px 0;
        gap: 50px;

        div {
            width: 80%;
            height: 300px;
        }
    }

    .holdWebinar {
        height: auto;
        flex-direction: column;
        padding: 50px 10px;
        gap: 40px;


        .holdWebinar1 {
            width: 90%;
        }

        .holdWebinar2 {
            width: 100%;

            img {
                width: 90%;
            }
        }
    }

    .holdTrust {
        height: auto;
        flex-direction: column-reverse;
        padding: 50px 10px;
        gap: 50px;

        div {
            width: 90%;

            img {
                width: 100%;
            }
        }
    }

    .holdTakeaways {
        height: auto;
        padding: 50px 10px;
        flex-direction: column;
        gap: 50px;

        div {
            width: 90%;

            div {
                width: 100%;

                h1 {
                    margin-bottom: 30px;

                }
            }

            img {
                width: 100%;
            }
        }
    }
.testimonial {
    height: auto;
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 30px;
}

.testimonial2 {
    flex-wrap: wrap;
    height: auto;
}
}