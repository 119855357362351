.holdForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 100px;
  padding-top: 100px;
}

.submit {
  width: 100% !important;
  background-color: #ffcc29 !important;
  border: #ffcc29 !important;
}

.layout1 {
  width: 50%;
  height: auto;
}

.map {
  height: 550px;
  width: 45%;

  iframe {
    height: 100%;
    width: 100%;
  }
}

.holdAddress {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 50px 100px;

  div {
    width: 300px;
    margin: 0;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 29px;
      color: #313131;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 230.68%;
      color: #4d4d4d;
    }
  }
}

.holdContact {
  padding: 50px 100px;
}

.cont {
  text-align: center;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 150.68%;
    color: #000000;
  }

                h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 165.68%;
    color: #000000;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 117.68%;
    color: #000000;
  }
}

.holdChatSell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0;
}

.imageNow {
  background-color: #ffcc29;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 30px;
  }
}

.visit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  div {
    text-align: center;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 29px;
      color: #313131;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 230.68%;
      color: #4d4d4d;
      margin: 0;
    }
  }
}
.holdImageNow {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.imageText2 {
  margin-top: 30px;
  text-align: center;
}

.Chat {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #313131;
}

.Speak {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #313131;
  margin: 10px 0;
}

.phone {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: #ffcc29;
}

.phone23 {
  text-decoration: none;
}

.holdNewsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
}

.Newsletter {
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
        h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
}

.NewsletterSubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 50px;

  input {
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 7px 20px;
  }
}

.holdLine2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  div {
    width: 40%;
    height: 2px;
    background-color: #ffcc29;
    border-radius: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .holdForm {
    padding: 10px;
    flex-direction: column;
  }

  .layout1 {
    width: 100%;
  }

  .map {
    width: 100%;
    height: 300px;
    margin: 20px 0;
  }

  .holdAddress {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .holdContact {
    padding: 10px;
  }

  .cont h5 {
    font-size: 18px;
  }

    .cont h1 {
    font-size: 22px;
  }

  .cont h4 {
    font-size: 18px;
  }

  .holdChatSell {
    flex-direction: column;
  }

  .NewsletterSubscribe {
    flex-direction: column;
    width: 100%;
  }

  .NewsletterSubscribe input {
    width: 80%;
  }

  .NewsletterSubscribe button {
    width: 80%;
  }
}
