.holdAll1 {
    margin-top: 80px;
}

.heroImage {
    height: 100vh;
    width: 100%;
    background-image: url("../../image/review.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;


    div {
        width: 100%;
        height: 100vh;
        background-color: #00000057;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
            margin-top: -230px;
            font-size: 70px;
            color: white;
        }
    }
}

.holdButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


.buttonActive {
    font-size: 1.125rem;
    background-color: #ffcc29;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: cursive;
    border: none;
}

.buttonNotActive {
    font-size: 1.125rem;
    background-color: #ffffff;
    color: black;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-family: cursive;
    border: none;


}

.holdButton2 {
    overflow: hidden;
    border-radius: 50px;
    border-width: 2px;
    border-style: solid;
    border-color: #ffcc29;
}
.reviewNow {
    text-align: center;
    margin-bottom: 50px;

    a {
        background-color: #ffcc29;
        color: white;
        padding: 15px;
        border-radius: 10px;
    }
}
.listOfVideo {
    margin: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        width: 80%;
        display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;

        iframe {
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 700px) {
    .heroImage {
        height: 400px;


        div {
            height: 400px;

            h1 {
                margin: 0;
                font-size: 50px;
            }
        }
    }

    .listOfVideo {

        div {
            width: 90%;
            display: flex;
                flex-wrap: wrap;

            iframe {
                width: 100%;
                height: 400px;
            }
        }
    }
}