.holdHeroImage {
  position: absolute;
  width: 100%;
  height: 85vh;
  z-index: -1;
}

.heroImage {
  width: 100%;
  height: 85vh;
}

.holdAllTestimonial {
  padding: 10px 150px;
  margin-top: 50px;
}

.holdGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: flex-start;
  gap: 20px;

  .gridShow {
    padding: 2rem;
    box-shadow: 5px 5px 5px #e0e0e0, -5px -5px 5px #e0e0e0;
    border-radius: 10px;

    .iconNow {
      text-align: center;
      font-size: 40px;
      color: #ffcc29;
      margin-bottom: 10px;
    }

    p {
      color: gray;
    }

    .holdName {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;

      h4 {
        font-size: 18px;
        margin-bottom: 2px;
      }

      p {
        font-size: 12px;
        margin: 0;
      }
    }
  }
}
.holdDiscover {
  width: 100%;
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 83px;
}

.holdDiscover1 {
  width: 530px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 77px;
    color: #ffffff;
    border-left: 2px solid white;
      padding-left: 30px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 192.68%;
    color: #ffffff;
    border-left: 2px solid white;
      padding-left: 30px;
      margin-top: -10px;
  }
}

.holdExplore {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.holdExplores {
  width: 75%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.circle {
  font-size: 45px;
  color: white;
  margin-right: 10px;
}

.holdCircle {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: white;
}

.explore {
  background: #ffcc29;
  border-radius: 5px;
  padding: 10px 40px;
  font-size: 20px;
  color: white;
  border: none;
}

.holdDiscover2 {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.herosmall {
  height: 140px;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.million {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 0px 0 10px 10px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffcc29;
    margin-bottom: 7px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #313131;
    margin-bottom: 7px;
  }
}

.holdSmall1 {
  width: 220px;
  position: absolute;
  top: 25%;
  right: 400px;
  transition: 0.5s;
}

.holdSmall1:hover {
  padding: 10px;
}

.holdSmall2:hover {
  padding: 10px;
}

.holdSmall3:hover {
  padding: 10px;
}

.holdSmall2 {
  width: 220px;
  position: absolute;
  top: 30%;
  right: 30px;
  z-index: 1;
  transition: 0.5s;
}

.holdSmall3 {
  width: 220px;
  position: absolute;
  top: 50%;
  right: 225px;
  transition: 0.5s;
}

.holdHome {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home1 {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #ececec;
  transition: 0.5s;
  cursor: pointer;

  img {
    height: 50px;
    margin-bottom: 10px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 165.68%;
    letter-spacing: 0.16em;
    color: #000000;
  }
}

.home1:hover {
  box-shadow: 5px 5px 5px #e8e8e8;
}

.home2:hover {
  box-shadow: 5px 5px 5px #e8e8e8;
}

.home2 {
  width: 50%;
  height: 100%;
  background-color: #ffcc29;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s;
  cursor: pointer;

  img {
    height: 50px;
    margin-bottom: 10px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 165.68%;
    letter-spacing: 0.16em;
    color: #000000;
  }
}

.holdTiwa {
  width: 1150px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 5px 100px;
  margin-top: 50px;
  gap: 20px;
}

.holdTiwaText {
  width: 50%;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    color: #313131;
    width: 90%;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 180%;
    color: #313131;
  }
}

.holdTiwaHouse {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 450px;
  }
}

.holdCircle1 {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #ffcc29;
}

.circle1 {
  font-size: 45px;
  color: #ffcc29;
  margin-right: 10px;
}

.proud {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 165%;
  color: #000000;
  margin-top: 50px;
  
}

.holdProud {
  padding: 5px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 60px 0;
  gap: 20px;

  img {
    height: 50px;
  }
}

.holdResidencesAndLand {
  width: 100%;
  height: auto;
  background: #efefef;
  padding: 20px 180px;
  margin-top: 70px;
}

.holdResidencesArrow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 165.68%;
    color: #313131;
    margin: 0;
  }

  button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 165%;
    color: #ffcc29;
    border: none;
  }
}

.holdLekkiHouse {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kokoLekkiImage {
  width: 100%;
  height: 100%;
  }
  
  .holdLekkiHouse12 {
    width: 320px;
}

.holdLekkiHouse1 {
  width: 290px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.HoldIbejuLekki {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;

  img {
    height: 21px;
    margin-right: 10px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #313131;
    margin: 0;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #313131;
    margin: 0;
  }
}

.holdBedLand {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 10px;
  gap: 20px;
}

.holdBed {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 25px;
    margin-right: 12px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 165.68%;
    color: #313131;
    margin: 0;
  }
}

.holdBooking {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;

  button {
    background: #ffcc29;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 5px 12px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 165.68%;
    color: #313131;
    margin: 0;
  }
}

.holdComfort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 200px;
  height: 100vh;
}

.holdRectangle31 {
  height: 500px;
  width: 45%;
  display: flex;
  justify-content: flex-end;

  img {
    height: 500px;
  }
}

.holdPriority {
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #313131;
    width: 400px;
  }

  p {
    width: 500px;
    margin: 15px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 185%;
    color: #313131;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 10px 25px;
    margin-top: 30px;
  }
}

.holdEnergy {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 600px;
  gap: 30px;

  div {
    width: 270px;
    padding: 5px 0;
    font-weight: 600;

    img {
      margin-right: 20px;
    }
  }
}

.holdLine {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;

  div {
    width: 40%;
    height: 2px;
    background-color: #ffcc29;
    border-radius: 5px;
  }
}

.eventText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 165.68%;
  color: #313131;
  margin-top: 15px;
  text-align: center;
}

.holdKokoLekkiImage {
  height: 300px;
}
.holdNews {
  padding: 10px 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.publish {
  background: #ff2943;
  border-radius: 5px;
  padding: 5px 20px;
  color: white;
  border: none;
}

.HoldIbejuLekki2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;

  img {
    height: 21px;
    margin-right: 10px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #313131;
    margin: 0;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #313131;
    margin: 0;
  }
}

.holdBed2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
  gap: 20px;
}

.holdBedLand2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  gap: 20px;
}

.holdBooking2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
  margin: 0;
  font-weight: 500;
}
.chanllenge {
  color: #ffcc29 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 165.68% !important;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.Testimonials {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 165.68%;
  color: #313131;
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
}

.testimonialNew {
  width: 30%;
  background-color: #ffcc29;
}

.testimonialNewImage {
  img {
    width: 100%;
    height: 300px;
  }
}

.testimonialNewText {
  padding: 20px;

  p {
    color: white;
  }

  h4 {
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0;
    text-align: right;
  }

  h6 {
    color: #FFF;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    margin-top: 5px;
  }
}
.clients {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 165.68%;
  color: #313131;
  margin: 0;
  text-align: center;
}

.AlabiSamuel {
  // padding: 20px 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.AlabiSamue2 {
  background: #ffffff;
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 350px;
  height: 280px;
  padding: 15px;
}

.testOO {
  margin-top: -80px !important;
}

.Ellipse1 {
  height: 50px;
  margin-right: 20px;
}

.MediaDev {
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    color: #000000;
    margin: 0;
  }

  .Dev {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #313131;
    margin: 0;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #313131;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.holdAlabiSamue {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 60px;
}

.holdFaGreater {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.FaLessThan {
  border: 2px solid #ffcc29;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 30px;
  transition: 0.5s;

  svg {
    color: black;
  }
}
.faGreater {
  border: 2px solid #ffcc29;
  padding: 10px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffcc29;
  transition: 0.5s;

  svg {
    color: white;
  }
}

.FaLessThan:hover {
  padding: 15px;
}

.faGreater:hover {
  padding: 15px;
}

.holdNewsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 90px;
}

.Newsletter {
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
    h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
}

.NewsletterSubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 50px;

  input {
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 7px 20px;
  }
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

.holdLine2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  margin-bottom: 90px;

  div {
    width: 50%;
    height: 2px;
    background-color: #ffcc29;
    border-radius: 5px;
  }
}

.holdTiwaHouses {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    height: 500px;
    border: 2px solid white;
      border-radius: 5px;
  }
}

.holdFooterModal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  button {
    width: 50%;
    padding: 10px;
    font-size: 20px;
    background-color: #ffcc29;
    color: white;
    border: none;
    border-radius: 5px;
  }
}

.holdTiwa1M {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.holdSwicher {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 70px;
}

.holdSwicher1 {
  background-color: #ffcc29;
  border-radius: 3px;
  width: 100px;
  padding: 2px;
  margin: 0 10px;
  cursor: pointer;
}

.holdSwicher2 {
  background-color: #d9d9d9;
  border-radius: 3px;
  width: 100px;
  padding: 2px;
  margin: 0 10px;
  cursor: pointer;
}

.selling {
  position: absolute;
  margin: 10px;
  background: #1abc0c;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 5px 20px;
  font-weight: bold;
}

.closed {
  position: absolute;
  margin: 10px;
  background: #ffcc29;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 5px 20px;
  font-weight: bold;
}

.out {
  position: absolute;
  margin: 10px;
  background: #ff2943;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 5px 20px;
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .holdAllTestimonial {
      padding: 20px;
    }
  
    .holdGrid {
      display: flex;
      flex-direction: column;
    }
  .holdAll {
    overflow: hidden;
  }
  .holdComfort {
    flex-direction: column;
    padding: 10px;
    width: 100%;
    margin: 40px 0;
    gap: 20px;
    height: 100%;
  }

  .NewsletterSubscribe {
    flex-direction: column;
    width: 100%;
    padding: 10px;

    input {
      width: 100%;
    }

    button {
      width: 100%;
      padding: 15px;
    }
  }

  .holdAnytime {
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }

  .holdNews {
    flex-direction: column;
    padding: 10px;
    row-gap: 50px;
  }

  .holdPriority {
    width: 100%;

    h3 {
      width: 100%;
    }

    p {
      width: 100%;
    }

    button {
      width: 100%;
    }
  }

  .holdRectangle31 {
    width: 100%;
    height: 450px;

    img {
      height: 450px;
      width: 100%;
    }
  }

  .holdEnergy {
    width: 100%;
  }

  .holdLekkiHouse1 {
    width: 90%;
  }
  .holdResidencesAndLand {
    padding: 10px;
  }
  .holdLekkiHouse1w {
    width: 90%;
  }
  .holdLekkiHouse {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  .holdResidencesArrow {
    padding: 0 20px;

    h3 {
      font-size: 20px;
    }
  }

  .holdProud {
    width: 100%;
    margin: 20px 0;
    padding: 10px;
    flex-wrap: wrap;
    column-gap: 20px;
      row-gap: 40px;

    img {
      width: 45%;
    }
  }

  .proud {
    margin-top: 20px;
  }
  .holdTiwaHouses {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      padding: 30px;
    }
  }
  .holdTiwaHouse {
    padding: 35px;
  }
  .holdExplores {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .holdTiwaText {
    width: 100%;
    font-size: 30px;
  }
  .holdTiwaText {
    width: 100%;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 48px;
      color: #313131;
      width: 100%;
      // text-align: center;
    }
  }
  .holdTiwa {
    height: auto;
    flex-direction: column;
    padding: 10px;
    margin-top: 30px;
    width: 100%;
  }
  .home1 {
    width: 50%;

    img {
      height: 30px;
      margin: 0;
    }

    p {
      font-size: 10px;
      text-align: center;
      line-height: normal;
      margin: 0;
    }
  }

  .home2 {
    width: 50%;

    img {
      height: 30px;
      margin: 0;
    }

    p {
      font-size: 11px;
      text-align: center;
      line-height: normal;
      margin: 0;
    }
  }
  .holdHome {
    height: 60px;
  }
  .holdSmall1 {
    // padding: 10px;
    position: static;
  }
  .holdSmall2 {
    padding: 10px;
    position: static;
  }
  .holdSmall3 {
    padding: 10px;
    position: static;
  }
  .holdSmall3 {
    width: 100%;
    position: static;
  }
  .herosmall {
    height: 220px;
    width: 100%;
  }
  .holdDiscover2 {
    width: 100%;
    padding: 10px;
  }
  .holdDiscover {
    height: 90vh;
    flex-direction: column;
    padding: 10px;
  }

  .holdHeroImage {
    height: 90vh;
  }

  .heroImage {
    height: 90vh;
  }

  .holdDiscover1 {
    width: 100%;

    h1 {
      font-size: 35px;
      line-height: normal;
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .holdExplore {
    width: 100%;
    margin-top: 10px;
    justify-content: space-between;
  }

  .holdAlabiSamue {
    overflow: scroll;
    width: 100%;
    gap: 10px 50px;
    flex-direction: column;
      padding: 10px;
    }
    
    .testimonialNew {
      width: 100%
  }

  .holdEnergy div {
    width: 170px;
  }
.holdResidencesNme {
  display: flex;
  justify-content: center;
}
}
