.holdLandHouses {
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 83px;
}

.holdLandHouses2 {
  width: 400px;

  img {
    height: 495px;
    width: 100%;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 165.68%;
      text-align: center;
      color: #ffffff;
      margin-top: -400px;
      width: 90%;
    }

    a {
      background: #ffcc29;
      border-radius: 10px;
      color: white;
      text-decoration: none;
      padding: 14px 16px;
      font-size: 15px;
    }
  }
}

.holdNewsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 150px;
  margin-top: 150px;
}

.Newsletter {
  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
    h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 165.68%;
    color: #000000;
    text-align: center;
  }
}

.NewsletterSubscribe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 50px;

  input {
    border: none;
    border-bottom: 1px solid #000000;
    outline: none;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 7px 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .holdLandHouses {
    padding: 10px;
    flex-direction: column;
  }

  .holdNewsletter {
    margin: 10px 0;
  }

  .NewsletterSubscribe {
    flex-direction: column;
    width: 100%;
    padding: 10px;

    input {
      width: 100%;
    }

    button {
      width: 100%;
      padding: 12px;
    }
  }

  .holdLandHouses2 {
    width: 100%;
  }
}
