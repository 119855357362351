@import url("https://fonts.googleapis.com/css2?family=Style+Script&display=swap");
.Layer1 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
}

.Layer1Pro1 {
  width: 1000px;
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.Layer1Pro1A1 {
  width: 50%;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #313131;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 148.02%;
    color: #000000;
  }
}

.Layer1Pro2A {
  margin-top: 25px;
}

.Layer1Pro2A1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #ffcc29;
  margin: 0;
}

.Layer1Pro2A2 {
  width: 150px;
  height: 3px;
  background-color: black;
  border-radius: 10px;
}

.Layer1Pro2A3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  width: 300px;
  margin: 15px 0;
}

.Layer1Pro2A4 {
  font-family: "Style Script";
  font-style: normal;
  //   font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
}

.holdImageBankStone {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  width: 60%;
  margin: 30px 0;
}
.holdImageKoMeB {
  height: 235px;
  width: 375px;
  margin: 0 2px;
}

.Layer3 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Layer3Pro {
  width: 1000px;
  margin: 50px 0;
  display: flex;
  justify-content: space-between;
}

.Layer3Pros {
  width: 100%;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #4d4d4d;
  }
}

.Layer3Pro1 {
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #313131;
    width: 500px;
    margin-bottom: 20px;
  }
}

.holdgray1Pic {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  margin: 50px 0;

  img {
    width: 250px;
    height: 60px;
  }
}

.Layer4 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Layer4Pro {
  width: 90%;
}

.Layer4Pro1 {
  height: 400px;
  width: 100%;
  display: grid;
  justify-content: space-between;
  margin-bottom: 100px;
  grid-template-columns: 390px 390px 390px;
}

.holdgray4loyalty3 {
  width: 90%;
  position: absolute;
  margin-bottom: 50px;
  z-index: -1;

  img {
    width: 100%;
    height: 400px;
  }
}

.Layer4Pro2 {
  width: 100%;
  padding: 30px;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 210.68%;
    color: #ffffff;
    border-bottom: 2px solid;
    width: fit-content;
    margin: 12px 0;
  }

  li {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 210.68%;
    color: #ffffff;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 210.68%;
    color: #ffffff;
    width: 280px;
  }
}

.lineMinkMe {
  border-right: 2px solid white;
  border-left: 2px solid white;
}

.Layer5 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Layer5Pro {
  width: 1200px;
  margin: 50px 0;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 1200px) {
  .Layer3Pro {
    width: 100%;
    margin: 20px 0;
  }
  .Layer1Pro1 {
    width: 100%;
    padding: 10px;
    margin: 20px 0;
    flex-direction: column;
  }

  .Layer3Pro1 h1 {
    width: 100%;
    font-size: 20px;
    margin-bottom: 10px;
  }

  .holdgray1Pic {
    margin: 20px 0;
    overflow-x: scroll;
  }

  .Layer1Pro1A1 {
    width: 100%;
  }

  .Layer4Pro {
    width: 100%;
  }

  .holdgray4loyalty3 img {
    width: 100%;
    height: 1200px;
  }

  .Layer4Pro1 {
    flex-direction: column;
    display: flex;
    height: 1200px;
    margin-bottom: 0;
  }
}
