.holdALl {
  background-color: #f1f1f1;
  padding-top: 80px;
}

.holdAllTeamText {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.holdAllTeamText1 {
  width: 800px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #313131;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 39px;
    color: #313131;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 141.02%;
    color: #313131;
    width: 520px;
  }
}

.holdAllExecutiveDirectors {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdAllExecutiveDirectors2 {
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #313131;
    text-align: center;
  }
}

.holdAllProfileKoko {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  margin: 50px 0;
  flex-wrap: wrap;
}

.holdImage {
  height: 240px;
  background-color: darkgrey;
  width: 220px;
}

.holdText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdText2 {
  background-color: white;
  width: 250px;
  height: 150px;
  margin-top: -70px;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 141.02%;
    color: #000000;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 141.02%;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    padding: 5px 15px;
    border: none;
  }
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1200px) {
  .holdAllTeamText {
    padding: 10px;
  }

  .holdAllTeamText1 {
    width: 100%;

    h5 {
      text-align: center;
    }

    h3 {
      font-size: 17px;
      text-align: center;
    }

    p {
      text-align: center;
      width: 100%;
    }
  }

  .holdAllProfileKoko {
    justify-content: center;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .callUs {
    width: 100%;
  }

  .Reach {
    font-size: 20px;
  }

  .question {
    width: 100%;
  }
}
