.holdAll {
  background-color: #efefef;
}

.starting {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 150px 0 40px 0px;

  div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 165.68%;
      color: #000000;
    }

    p {
      text-align: center;
      font-size: 18px;
    }
  }
}

.holdProperty1 {
  padding: 50px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdProperty2 {
  width: 850px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holdSearch {
  border: 2px solid rgba(217, 217, 217, 0.5);
  border-radius: 25px;
  padding: 4px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchKoko {
  height: 100%;
  width: 82%;
  border: none;
  outline: none;
  color: gray;
  background-color: transparent;
}

.searchIcon {
  background-color: #ffcc29;
  color: white;
  width: 40px;
  height: 40px;
  padding: 9px;
  border-radius: 50%;
}

.holdSelect {
  border: 2px solid rgba(217, 217, 217, 0.5);
  border-radius: 20px;
  padding: 10px;

  select {
    width: 190px;
    border: none;
    outline: none;
    font-weight: 500;
    background-color: transparent;
  }
}

.searchButin {
  background: #4d4d4d;
  border-radius: 10px;
  font-size: 20px;
  color: white;
  border: none;
  padding: 7px 25px;
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

.holdDrainage {
  display: flex;
  justify-content: center;
  padding: 5px 100px;
}

.holdAllDrainage1 {
  width: 800px;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 50px;
}

.holdImage {
  height: 300px;
}

.holdAllDrainage2 {
  width: 45%;
  border-radius: 20px;
  background-color: white;
}

.Outofsales {
  background: #ff2943;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 10px 15px;
  position: absolute;
  margin: 10px;
}

.SalesClosed {
  background: #ffcc29;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 10px 15px;
  position: absolute;
  margin: 10px;
}

.SalesNo {
  background: #388e3c;
  border-radius: 5px;
  color: white;
  font-size: 25px;
  padding: 10px 15px;
  position: absolute;
  margin: 10px;
}

.SalesPend {
  background: #f57c00;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  padding: 10px 15px;
  position: absolute;
  margin: 10px;
}

.holdImage {
  img {
    width: 100%;
    height: 100%;
  }

  div {
    background-color: rgba(204, 204, 204, 0.57);
    padding: 10px 30px;
    margin-top: -85px;
    position: absolute;
    width: 358px;

    h1 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 165.68%;
      color: #000000;
      padding: 5px 0;
      margin: 0;
    }

    p {
      background-color: #ffcc29;
      padding: 3px 4px;
      margin: 0;
      width: fit-content;
      color: white;
    }
  }
}

.holdImageText {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 25px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      height: 40px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      margin: 0;
      line-height: 165.68%;
      color: #313131;
    }
  }
}

.holdImageExploreButin {
  text-align: center;
  margin-bottom: 20px;

  button {
    background: #515151;
    border-radius: 5px;
    color: white;
    padding: 6px 20px;
    font-size: 20px;
    border: none;
  }
    a {
      background: #515151;
      border-radius: 5px;
      color: white;
      padding: 6px 20px;
      font-size: 20px;
      border: none;
    }
}

.locationPoint {
  width: 18px !important;
  height: 18px;
  margin-right: 5px;
}

.landSize {
  background-color: #515151;
  color: white;
  padding: 6px 15px;
  margin: 6px 15px;
  width: fit-content;
  font-size: 12px;
  font-weight: bold;
}

@media only screen and (max-width: 1200px) {
  .starting {
    padding: 10px;

    div {
      width: 100%;

      h3 {
        font-size: 20px;
        text-align: center;
      }

      p {
        font-size: 15px;
      }
    }
  }

  .holdProperty1 {
    padding: 20px;
    overflow: scroll;
  }

  .holdDrainage {
    padding: 10px;
  }

  .holdAllDrainage1 {
    width: 100%;
  }

  .holdAllDrainage2 {
    width: 100%;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .Reach {
    font-size: 25px;
  }

  .callUs {
    width: 100%;
    padding: 12px;
  }
}
