.close {
  width: 700px;
  height: 140px;
  color: #ffcc29;
}

.successText {
  font-size: 22px;
  color: #ffcc29;
}

.closeButin {
  background-color: #ffcc29 !important;
  border-color: #ffcc29 !important;
}

@media only screen and (max-width: 800px) {
  .close {
    width: 100%;
    height: 140px;
    color: #ffcc29;
  }
}
