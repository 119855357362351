.holdALl {
  padding-top: 80px;
  background-color: #f1f1f1;
}

.holdAllTeamText {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.holdAllTeamText1 {
  width: 800px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #313131;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 39px;
    color: #313131;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 141.02%;
    color: #313131;
    width: 800px;
  }
}

.holdAllExecutiveDirectors {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdAllExecutiveDirectors2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
    width: 1200px;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #313131;
    text-align: center;
  }
}

.holdAllProfileKoko {
  width: 89%;
    align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: flex-start;
  margin: 50px 0;
}

.holdImage {
  background-color: darkgrey;
  height: 340px;
    width: 320px;

  img {
    width: 100%;
    height: 100%;
  }
}

.holdText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdText2 {
  align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between;
    margin-top: -70px;
    padding: 10px;
    text-align: center;
    width: 250px;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 141.02%;
    color: #000000;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 141.02%;
    text-align: center;
    color: #000000;
    margin-bottom: 10px;
  }

  button {
    background: #ffcc29;
    border-radius: 5px;
    color: white;
    font-size: 15px;
    padding: 5px 15px;
    border: none;
  }
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

.NonBoardofDirectors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90vh;
  width: 100%;
}

.NonBoardofDirectors2 {
  height: 90vh;
  width: 50%;

  img {
    height: 100%;
    width: 100%;
  }
}

.modalBodyV {
  padding: 0 !important;
}
.NonBoardofDirectors3 {
  width: 50%;
  height: 90vh;
  padding: 10px;
  background-color: white;
  overflow-y: scroll;

  p {
    overflow-y: scroll;
    height: auto;
    margin: 0;
    padding: 10px;
    text-align: left;
  }
}

.CloseTas {
  display: none;
}

@media only screen and (max-width: 700px) {
  .holdAllTeamText {
    padding: 10px;
  }

  .CloseTas {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    background-color: white;
    border: none;
  }

  .holdAllTeamText1 {
    width: 100%;

    h5 {
      text-align: center;
    }

    h3 {
      font-size: 17px;
      text-align: center;
    }

    p {
      text-align: center;
      width: 100%;
    }
  }

  .holdAllProfileKoko {
    justify-content: center;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .callUs {
    width: 100%;
  }

  .Reach {
    font-size: 20px;
  }

  .question {
    width: 100%;
  }

  .NonBoardofDirectors {
    flex-direction: column;
    height: auto;
  }

  .NonBoardofDirectors2 {
    width: 100%;
    height: 300px;
  }

  .NonBoardofDirectors3 {
    width: 100%;
    height: auto;

    p {
      overflow: initial;
      height: auto;
      text-align: left;
    }
  }
  .CloseTas {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 20px;
    background-color: white;
    border: none;
  }

  .holdText2 {
    background-color: white;
    width: 200px;
    height: auto;
    margin-top: -70px;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
}
