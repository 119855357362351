.holdALl {
  background-color: #f1f1f1;
}

.holdHouses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 40px 0;
  padding-top: 150px;

  img {
    height: 500px;
  }
}

.holdHousesText {
  text-align: center;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    color: #313131;
    margin-top: 20px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #313131;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;

    button {
      background: #ffcc29;
      color: white;
      padding: 7px 15px;
      font-size: 20px;
      border: none;
      border-radius: 5px;

      svg {
        margin-right: 12px;
        font-size: 40px;
      }
    }
  }
}

.holdProvision {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdProvision2 {
  width: 550px;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
}

.blackHeadText {
  text-align: center;
  background-color: black;
  color: white;
  padding: 15px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #ffffff;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

.holdImageText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  div {
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0;

    img {
      height: 50px;
    }

    p {
      font-size: 15px;
    }
  }
}

.holdApartment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.holdApartment2 {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  margin: 50px 0;
  gap: 90px;
}

.holdBedroom {
  width: 45%;

  h1 {
    display: flex;

    .holdBedroomSPan1 {
      background-color: #ffcc29;
      color: white;
      font-size: 20px;
      padding: 10px 20px;
      text-align: center;
    }

    .holdBedroomSPan2 {
      background-color: #019785;
      color: white;
      font-size: 18px;
      padding: 10px 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;

    svg {
      width: 20px;
      height: 20px;
      font-size: 40px;
      color: #ffcc29;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #313131;
    }
  }
}

// wefwef

.holdBedroom2 {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}

.holdSMallImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdSMallImage2 {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  div {
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;

    img {
      height: 180px;
    }
  }
}

.holdApartments {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.holdApartment2s {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  flex-direction: row-reverse;
  margin: 50px 0;
  gap: 90px;
}

.holdBedrooms {
  width: 45%;

  h1 {
    background-color: #ffcc29;
    color: white;
    font-size: 25px;
    padding: 20px;
    text-align: center;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;

    svg {
      font-size: 40px;
      color: #ffcc29;
      width: 30px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #313131;
    }
  }
}

.holdSMallImages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdSMallImage2s {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  div {
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;

    img {
      height: 180px;
    }
  }
}

.holdLekkiPrideIEstate {
  width: 100%;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdLekkiPrideIEstate2 {
  width: 770px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 303.68%;
    color: #ffffff;
    background-color: #ffcc29;
    width: fit-content;
    padding: 0 50px;
  }

  div {
    margin: 20px 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 200.18%;
    color: #313131;
  }
}

.holdLekkiPrideIEstateM {
  width: 100%;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdLekkiPrideIEstate2M {
  width: 770px;
  background-color: white;
  padding: 20px;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 156.18%;
    color: #313131;
  }
}

.holdLekkiPrideIEstate2M2 {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 50px;
}

.holdMap {
  width: 50%;
}

.holdForm {
  width: 50%;
}

.mapKoko {
  width: 100%;
  height: 400px;
}

.formButton {
  background-color: #ffcc29;
  color: white;
  padding: 7px 20px;
  font-size: 17px;
  border: none;
  border-radius: 5px;
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .holdHouses {
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .holdHousesText {
    h1 {
      font-size: 20px;
      margin-top: 10px;
    }

    div {
      padding: 10px;
      width: 100%;
      gap: 10px;
    }
  }

  .holdApartment2 {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    margin: 20px 0;
  }

  .holdBedroom {
    width: 100%;
  }

  .holdBedroom2 {
    width: 100%;
    padding: 10px;
  }

  .holdSMallImage {
    padding: 10px;
  }

  .holdSMallImage2 {
    width: 100%;
    overflow: scroll;
    gap: 10px;

    div {
      width: 500px;

      img {
        width: 500px;
      }
    }
  }

  .holdApartment2s {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }

  .holdBedrooms {
    width: 100%;
  }

  .holdSMallImages {
    overflow: scroll;
  }

  .holdLekkiPrideIEstate2M2 {
    flex-direction: column;
  }

  .holdForm {
    width: 100%;
  }

  .holdMap {
    width: 100%;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .Reach {
    font-size: 25px;
  }

  .callUs {
    width: 100%;
    padding: 12px;
  }

  .holdLekkiPrideIEstateM {
    padding: 10px;
  }
}
