@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Style+Script&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #ffcc29 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ffcc293b !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn:focus-visible {
  border-color: #ffcc29 !important;
  box-shadow: 0 0 0 0.25rem #ffcc293b !important;
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 1048px !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffcc29;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffcc29;
}

a {
  text-decoration: none !important;
}
