.holdALl {
  background-color: #f1f1f1;
}

.holdOurBlog {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;

  div {
    width: 350px;
    text-align: center;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 45px;
      text-align: center;
      color: #313131;
    }
  }
}

.learnMore {
  padding: 10px 20px;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffcc29;
  border: none;
  color: white;
  border-radius: 5px;
}

.AllCategory {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AllCategory2 {
  width: 900px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.HousingPublished {
  margin-bottom: 15px;
}

.HousingPublished2 {
  background: #86c0d9;
  border-radius: 5px;
  padding: 5px 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  color: #026281;
  margin-right: 40px;
}

.HousingPublished3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #313131;
}

.Rectangle123 {
  height: 100%;
  width: 400px;
}

.Developtheskills {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 186.52%;
  color: #515151;
  margin: 8px 0;
}

.ADMINMARCH {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 186.52%;
  color: #9f9f9f;
  margin: 0;
}

.holdSelect {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 0;
  }
}

.AllCategories {
  border: 1px solid #797979;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px 15px;
  color: rgba(125, 8, 22, 0.5);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.AllCategories2 {
  border: 1px solid #797979;
  border-radius: 10px;
  background-color: transparent;
  padding: 10px 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
}

.holdEstateHouses {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdEstateHouses2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 900px;
}

.holdEstateHousesKoko {
  width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.holdEstateHousesKoko3 {
  width: 270px;
  box-shadow: 5px 5px 5px lightgray, -5px -5px 5px lightgray;
    padding: 10px;
    border-radius: 5px;

  img {
    width: 100%;
    height: 200px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #cb2136;
    width: fit-content;
    background: rgba(125, 8, 22, 0.2);
    border-radius: 10px;
    padding: 8px 10px;
    margin-top: 10px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #313131;
    margin: 0;
  }

  text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 175%;
    color: #9f9f9f;
  }
}

.holdEstateHousesKoko3:hover {
  box-shadow: 5px 5px 5px #dae4f6, -5px -5px 5px #dae4f6;
}
.NEWSLETTER {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #313131;
}

.holdSubscribe {
  background-color: #f1f1f1;
  border: 1px solid gray;
  width: 100%;
  border-radius: 50px;
  overflow: hidden;

  input {
    border: none;
    background-color: transparent;
  }

  button {
    background-color: #ffcc29;
    border: none;
    color: white;
    padding: 10px;
  }
}

.FOLLOWUS {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #313131;
  margin: 20px 0;
}

.Twitter {
  background-color: white;
  padding: 13px;
  border-radius: 5px;
  margin: 10px 0;
}

@media only screen and (max-width: 1100px) {
  .holdOurBlog {
    padding: 10px;

    div {
      width: 100%;

      h4 {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }

  .AllCategory2 {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .Rectangle123 {
    width: 100%;
  }

  .holdSelect {
    div {
      width: 100%;
      column-gap: 15px;
      margin: 20px 0;
      overflow: scroll;
    }
  }

  .holdEstateHouses2 {
    width: 100%;
    flex-direction: column;
  }

  .holdEstateHousesKoko {
    width: 100%;
    justify-content: center;
  }

  .newLetter {
    width: 100%;
    padding: 10px;
  }

  .holdSubscribe {
    display: flex;
    justify-content: space-between;
  }
}
