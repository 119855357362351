.holdAll {
  height: 15vh;
  padding: 0 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #e3e3e3;
  position: absolute;
    top: 0;
    z-index: 20;
    background-color: white;
    width: 100%;
}

.holdList {
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  gap: 50px;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    z-index: 4;
    font-weight: 600;

    a {
      color: #313131;
      text-decoration: none !important;
      font-weight: 600;
    }
  }

  li:hover .holdSubList {
    display: block;
  }
}

.holdSubList {
  border: 1.5px solid #ebebeb;
  position: absolute;
  top: 60px;
  display: none;
  background-color: white;
  padding: 0;
  transition: 0.5s;

  li {
    padding: 10px 40px;
    list-style: none;
    transition: 0.5s;
    font-weight: 600;

    a {
      color: #313131;
      text-decoration: none !important;
      font-weight: 600;
    }
  }

  li:hover {
    background-color: #eaeaea;
  }
}

.callUS {
  background: #ffcc29;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  font-size: 15px;
  color: white;
}

.logoDivs {
  display: none;
}
.logoDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdLogo {
  height: 70px;
}

.lineMenu {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navMenu {
  display: none;
}

.holdList2 {
  display: none;
}
@media only screen and (max-width: 900px) {
  .logoDivs {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoDiv {
    display: none;
  }
  .callUS {
    width: 100%;
  }
  .navMenu {
    display: block;
    font-size: 40px;
  }
  .holdAll {
    padding: 0 15px;
    height: auto;
    align-items: center;
    justify-content: space-between;
  }

  .holdList {
    flex-direction: column;
    border: 1px solid lightgray;
    background-color: white;
    width: 200px;
    padding: 0;
    position: absolute;
    top: 70px;
    right: 0px;
    z-index: 3;
    gap: 0;
  }

  .holdSubList {
    right: 200px;
    width: 100%;
    text-align: center;

    li {
      padding: 10px;
    }
  }
}
