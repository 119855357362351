.holdALl {
  background-color: #f1f1f1;
}
.holdImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 70px 0;
  margin-top: 50px;
}

.holdImage2 {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
    border: 2px solid white;
      border-radius: 5px;
  }
}

.holdPlayButin {
  display: flex;
  justify-content: space-between;
  width: 85%;
  flex-direction: column;
  margin-top: -400px;
  margin-bottom: 150px;
  align-items: flex-end;
  row-gap: 40px;
  padding-top: 30px;

  button {
    width: 260px;
    background-color: #ffcc29;
    padding: 15px 25px;
    font-size: 18px;
    color: white;
    font-weight: bold;
    border: none;
    margin-right: 270px;

    svg {
      font-size: 45px;
      margin-right: 10px;
    }

    img {
      width: 40px;
    }
  }
}

.holdPlayInee {
  width: 260px;
  background-color: #019785 !important;
  padding: 15px 25px;
  font-size: 18px;
  color: white;
  font-weight: bold;
  border: none;
  margin-right: 0 !important;
}

.holdAnytime {
  width: 100%;
  background-color: rgba(204, 204, 204, 0.57);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  padding: 50px;
  text-align: center;
  gap: 40px;
}

.Reach {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  color: #313131;
}

.question {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #313131;
}

.callUs {
  background: #ffcc29;
  border-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 20px;
  border: none;

  svg {
    margin-right: 10px;
  }
}

.holdLekkiPrideIEstateM {
  width: 100%;
  padding: 70px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdLekkiPrideIEstate2M {
  width: 770px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 156.18%;
    color: #313131;
  }
}

.holdLekkiPrideIEstate2M2 {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 50px;
}

.holdMap {
  width: 50%;
}

.holdForm {
  width: 50%;
  }

.mapKoko {
  width: 100%;
  height: 400px;
}

.formButton {
  background-color: #ffcc29;
  color: white;
  padding: 7px 20px;
  font-size: 17px;
  border: none;
  border-radius: 5px;
  }

.holdApartment {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.holdApartment2 {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: self-start;
  margin: 50px 0;
  gap: 90px;
}

.holdBedroom {
  width: 45%;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 165.68%;
    color: #313131;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    h4 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #313131;
      width: 400px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #313131;
    }
  }
}

.SQM {
  margin: 12px 0;
  background-color: #ffcc29;
  color: white;
  font-size: 15px;
  padding: 5px 10px;
  width: fit-content;
  font-weight: bold;
}

.holdBedroom2 {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    border: 2px solid white;
      border-radius: 5px;
  }
}

.holdSMallImage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holdSMallImage2 {
  width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  div {
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid white;

    img {
      height: 180px;
    }
  }
}

.holdViewPaymentPlan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0;
}

.holdViewPaymentPlan2 {
  width: 1010px;
  margin: 50px 0;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 165.68%;
    color: #313131;
    text-align: center;
  }
}

.holdViewCityPricing {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 0;
}

.holdViewCityPricing2 {
  width: 250px;
}

.holdViewCityPricing3 {
  background: #019785;
  border-radius: 10px 10px 0px 0px;
  padding: 15px;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    // line-height: 0;
    padding: 10px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    text-align: center;
    color: #ffffff;
    line-height: 0;
    padding: 10px;
    margin-bottom: 7px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 165.68%;
    text-align: center;
    color: #ffffff;
    margin-top: 15px;
  }

  div {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;

    h5 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 165.68%;
      text-align: center;
      color: #313131;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 165.68%;
      text-align: center;
      color: #313131;
      margin-bottom: 5px;
    }
  }
}

.holdViewPaymentPlanMN {
  background-color: white;
  padding: 20px;
  text-align: center;
  margin: 0;

  button {
    background: #ffcc29;
    border-radius: 10px;
    padding: 13px;
    color: white;
    font-weight: bold;
    border: none;
  }
}

.holdAllPriceLand1 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.display {
  display: block;
}

.notDisplay {
  display: none;
}

.holdAllPriceLand2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    margin-top: 20px;
  }
}

.holdButin {
  width: 100%;

  .meter {
    width: 25%;
    padding: 7px 5px;
    border-radius: 0px 0px 0px 0px;
    border: 2px solid #019785;
    color: white;
    font-weight: bold;
    background-color: #019785;
  }
  .outMeter {
    width: 25%;
    padding: 10px 15px;
    border-radius: 0px 0px 0px 0px;
    border: none;
    color: #019785;
    background-color: white;
    font-weight: bold;
  }
}

.holdTableMeNow {
  width: 100%;
}

.holdTableMeNow {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  table {
    width: 100%;
    background-color: white;
    color: black;
    box-shadow: 4px 4px 16px #d9d9d9;
    border-radius: 0px 0px 5px 5px;

    thead tr th {
      text-align: center;
      padding: 10px 15px;
      border-bottom: 2px solid rgba(255, 255, 255, 0.179);
    }
    tbody tr td {
      text-align: center;
      padding: 20px 15px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.179);
    }
  }
}
@media only screen and (max-width: 1000px) {
  .outMeter {
    width: 30% !important;
  }

  .holdTableMeNow table tbody tr td {
    text-align: center;
    padding: 20px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.179);
  }

  .holdHouses {
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .holdHousesText {
    h1 {
      font-size: 20px;
      margin-top: 10px;
    }

    div {
      padding: 10px;
      width: 100%;
      gap: 10px;
    }
  }

  .holdApartment2 {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    margin: 20px 0;
  }

  .holdBedroom {
    width: 100%;
  }

  .holdBedroom2 {
    width: 100%;
    padding: 10px;
  }

  .holdSMallImage {
    padding: 10px;
  }

  .holdSMallImage2 {
    width: 100%;
    overflow: scroll;
    gap: 10px;

    div {
      overflow: initial;
      width: 500px;

      img {
        width: 250px;
      }
    }
  }

  .holdApartment2s {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }

  .holdBedrooms {
    width: 100%;
  }

  .holdSMallImages {
    overflow: scroll;
  }

  .holdLekkiPrideIEstate2M2 {
    flex-direction: column;
  }

  .holdForm {
    width: 100%;
  }

  .holdMap {
    width: 100%;
  }

  .holdAnytime {
    flex-direction: column;
    padding: 20px;
    gap: 10px;
  }

  .Reach {
    font-size: 25px;
  }

  .callUs {
    width: 100%;
    padding: 12px;
  }

  .holdLekkiPrideIEstateM {
    padding: 10px;
  }

  .holdImage {
    padding: 10px;
  }

  .holdImage2 {
    width: 100%;
  }

  .holdPlayButin {
    width: 100%;
    justify-content: center;
    padding: 10px;
    margin-top: -215px;
    row-gap: 20px;
    align-items: center;
    margin-bottom: 0;

    button {
      padding: 10px;
      margin-right: 0;

      svg {
        font-size: 30px;
      }

      img {
        width: 30px;
      }
    }
  }

  .holdALl {
    overflow: hidden;
  }

  .holdViewCityPricing {
    flex-direction: column;
    gap: 30px;
  }
}
